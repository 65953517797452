import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

export default function Index({ data: { site } }) {
  useEffect(() => {
    window.location.href = window.location.origin + '/blog';
  }, []);

  return <Layout site={site} />;
}

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
  }
`;
